















































import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { cloneDeep, debounce } from "lodash";
import { Route, NavigationGuardNext } from "vue-router";

import NetworkRequestModel from "@/settings/models/networks-management/NetworkRequestModel";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";
import ValidUtil from "@/shared/utils/ValidUtil";
import NetworkResponseModel from "@/settings/models/networks-management/NetworkResponseModel";
import { AppSection } from "@/shared/models";

@Component
export default class NewNetworkView extends mixins(UnsavedChangesMixin) {
  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];

  localValue = new NetworkRequestModel();
  isValid = true;

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return cloneDeep(this.localValue);
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  get isSavingInProgress(): boolean {
    return this.$store.state.networksManagementStore.isSavingInProgress;
  }

  get network(): NetworkResponseModel {
    return this.$store.state.networksManagementStore.network;
  }

  get networkNameIsUnique(): boolean {
    return this.$store.state.networksManagementStore.networkNameIsUnique;
  }

  get networkNameErrorMessages(): Array<string> {
    return !this.networkNameIsUnique
      ? [this.$lang("shared.errors.notUnique")]
      : [];
  }

  @Watch("localValue.networkName")
  watchName = debounce(() => {
    this.$store.dispatch(
      "checkNetworkNameUniqueness",
      this.localValue.networkName
    );
  }, 500);

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("appAdmin.networksManagement.newNetworkTitle")
    );
    this.handleWatchingUnsavedChanges();
  }

  mounted() {
    const { networkId, networkName } = this.$route.query;

    if (networkId && networkName) {
      this.localValue.networkName = `${networkName}_copy`;
    }
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      this.currentRoutePath === from.path &&
      !this.isSavedForm
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  handleCancel() {
    this.$router.push({
      name: AppSection.NETWORKS_MANAGEMENT,
      params: { id: this.applicationId },
    });
  }

  async handleSave() {
    const { networkId, networkName } = this.$route.query;

    if (networkId && networkName) {
      await this.$store.dispatch("copyNetwork", {
        id: networkId,
        payload: this.localValue,
      });
    } else {
      await this.$store.dispatch("createNetwork", this.localValue);
    }

    this.isSavedForm = true;
    this.$router.push({
      name: "networks-management_view",
      params: {
        networkId: String(this.network.id),
      },
    });
  }
}
