import NetworkResponseModel from "./NetworkResponseModel";

export class NetworkAuthenticationRequestModel {
  constructor(
    public userName?: string,
    public password?: string,
    public bearer?: string
  ) {}
}

export default class NetworkRequestModel {
  constructor(public networkName = "") {}

  static ofSource(
    model: NetworkRequestModel | NetworkResponseModel
  ): NetworkRequestModel {
    return new NetworkRequestModel(model.networkName);
  }
}
